<template>
<div id="app">
  <CRow>
    <CCol sm="8" md="8">
        <CCard>
          <CCardHeader>
            <CRow>
             <CCol col="9" class="text-left"><h4>Send Notifications</h4></CCol>
             <CCol col="3" class="text-right">
                    <b-form-checkbox
                      v-model="cbbfile"
                      @change="onModifycbbfile"
                      name="cbbfile"
                      value="true"
                      unchecked-value="false">
                      Batch Send
                    </b-form-checkbox>

             </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CForm>
              <b-tabs content-class="mt-3">
               <b-tab title="Send Notification">
                <CRow>
                  <CCol col="12" class="text-left">
                    <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                  </CCol>
                </CRow>

                <div class="text-center">
                  <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
                </div>


                <b-row v-if="pbar">
                    <b-col sm="12">
                     <!-- <b-progress height="2rem" variant="success" :value="value" :max="max" show-progress animated></b-progress> -->
                     <b-progress :max="max" height="2rem" show-progress animated>
                      <b-progress-bar :value="value" variant="success" :label="`${((value / max) * 100)}%`"></b-progress-bar>
                    </b-progress>
                    </b-col>
                </b-row><br/>

                  <div v-if="seenmselect">
                    <b-row>
                      <b-col sm="3">
                         <label for="input-small" >Select Users </label>
                      </b-col>
                      <b-col sm="7">
                          <multiselect @select="toggleUserSelected" @remove="toggleUserUnSelect" v-model="user" :disabled="!isDisabledlu" :options="optusers" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Phone Number" label="name" track-by="name" :preselect-first="true">
                          <template slot="selection3" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                          </multiselect>
                      </b-col>
                      <b-col sm="2">
                         <b-form-checkbox
                          v-model="cball"
                          @change="onModifycball"
                          name="cball"
                          value="true"
                          unchecked-value="false">
                          All
                         </b-form-checkbox>
                      </b-col>
                    </b-row><br/>
                  </div>


                  <div class="form-group">
                           <b-row>
                             <b-col sm="3">
                                <label for="input-small" >Template</label>
                             </b-col>
                             <b-col sm="9">
                                <b-form-select v-model="templId" @change="setBytemplate" :options="optTemplate"></b-form-select>
                             </b-col>
                          </b-row>
                  </div>



                  <div v-if="seenfile">
                    <b-row>
                      <b-col sm="3">
                         <label for="input-small" >Upload File </label>
                      </b-col>
                      <b-col sm="9">
                         <!--input type="file" :disabled="!isDisabledfile" ref="myFile" @change="selectedFile"-->
                         <input type="file":disabled="!isDisabledfile" @change="onFileChange" />
                      </b-col>
                    </b-row><br/>

                    <b-row>
                      <b-col sm="3"></b-col>
                      <b-col sm="9">
                         <textarea v-model="text" :readonly="true" cols="45" ></textarea>
                      </b-col>
                    </b-row><br/>
                  </div>

                  <CInput
                    type="text"
                    label="Title"
                    v-model="title"
                    placeholder="Type Title"
                    maxlength="40"
                    description="Maximum 40 characters"
                    horizontal
                  />

                  <div class="form-group">
                           <b-row>
                             <b-col sm="3">
                                <label for="input-small" >Category</label>
                             </b-col>
                             <b-col sm="9">
                                <b-form-select v-model="defoptCat" :options="optCat"></b-form-select>
                             </b-col>
                          </b-row>
                  </div>

                  <CTextarea
                      label="Preview Message"
                      v-model="message"
                      placeholder="Type Preview Message..."
                      maxlength="140"
                      description="Maximum 140 characters"
                      horizontal
                      rows="2"
                  />

                  <CTextarea
                      label="Long Message"
                      v-model="longMessage"
                      placeholder="Type Long Message..."
                      maxlength="180"
                      description="Maximum 180 characters"
                      horizontal
                      rows="5"
                  />

                  <b-row>
                    <b-col sm="3"></b-col>
                    <b-col sm="9">
                      <b-button-group>
                      <b-button style="width:100px;" v-on:click="actionReset()" variant="secondary">Reset</b-button> &nbsp;&nbsp;
                      <b-button style="width:100px;" v-on:click="actionSubmit()" variant="primary">Send</b-button> &nbsp;&nbsp;

                      <vue-excel-xlsx
                          v-if="seenBtnDwld"
                          style="color:green"
                          :data="data"
                          :columns="columns"
                          :filename="'Failed-Sent-Number'"
                          :sheetname="'Failed-Sent-Number'"
                          >
                          Download Failed
                      </vue-excel-xlsx>
                      </b-button-group>
                    </b-col>
                  </b-row><br/>
                </b-tab>
                <b-tab title="Send to All Error Report" >
                   <report />
                </b-tab>
              </b-tabs>
           </CForm>
          </CCardBody>
        </CCard>
      </CCol>
     </CRow>

</div>
</template>

<script>
 import AuthService from '@/api/AuthService.js';
 import AccountService from '@/api/AccountService.js';
 import VueTimepicker from 'vue2-timepicker';
 import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
 import Multiselect from 'vue-multiselect';
 import moment from 'moment';
 import readXlsxFile from 'read-excel-file';
 import Reports from './report';


  export default {
    name: 'notification',
    components: {
      VueTypeaheadBootstrap,
      Multiselect,
      VueTimepicker,
      'report': Reports,
    },
    data() { var date = new Date();
      return {
          columns :[
                    {
                        label: "Invalid Phone Number / ID",
                        field: "userId",
                    },
          ],
          seenBtnDwld:false,
          data:[
                  //  {
                  //       userId: "000000000",

                  //   },
          ],
          spinner:false,
          seenmselect:true,
          seenfile:false,
          cbbfile:false,
          text:'',
          user:null,
          baseUserId:[],
          basePhoneNum:[],
          invalid:false,
          optusers:[],
          defoptCat:'',
          optCat:[],
          failSent:[],
          optTemplate:[],
          templId:'',
          isDisabledlu:true,
          isDisabledfile:true,
          cball:false,
          title:'',
          message:'',
          longMessage:'',
          seen:false,
          pbar:false,
          value:0,
          max:0,
          msg :'',
          color:''
        };
    },
    created () {

      this.fetchUsers();
      this.getNotifCategoryList();
      this.getdNotifTemplateList();

    },
    methods: {

      onFileChange(event) {
        let xlsxfile = event.target.files ? event.target.files[0] : null;
        readXlsxFile(xlsxfile).then((rows) => {
            var temp = [];
            for (var key in rows) {
                var IDs = rows[key];
                 for (var key2 in IDs) {
                      temp.push(IDs[key2]);
                 }
            }

            var implode = temp.join(",");
            this.text = implode;

        })
      },

      setBytemplate(){
        this.getNotifTemplateById(this.templId);
      },

      onModifycbbfile(){

         var checkFile = this.stringToBoolean(this.cbbfile);

         if(checkFile){
              this.seenfile=true; this.isDisabledlu= false;
              //this.msg   = "Use 'comma' for user ID as delimiter inside the file";
              this.msg   = "Use excel file (*.xlsx) Phone Number under column (A)";
              this.color = 'info'; this.seen  = true;
              setTimeout( () =>  this.seen=false , 5000);
         } else {  this.seenfile=false;  this.isDisabledlu= true; this.cbbfile = false; this.text=""; }

      },

      selectedFile() {

        let file = this.$refs.myFile.files[0];
        if(!file || file.type !== 'text/plain') return;

        let reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onload =  evt => {
            this.text = evt.target.result;
        }
        reader.onerror = evt => {
          console.error(evt);
        }
      },

      stringToBoolean: function(string){
          switch(String(string).toLowerCase().trim()){
              case "true": case "yes": case "1": return true;
              case "false": case "no": case "0": case null: return false;
              default: return Boolean(string);
          }
      },

      confirmBeforeSubmit(){

        if (confirm('Notification will be sending, Are you sure ? ')) {
             this.actionSubmit();
        }
      },

      actionSubmit(){
        let params       = {};
        var arrUsers     = [];
        var arrUser      = [];
        var validate     = [];

        var baseUserId   = this.baseUserId;
        var basePhoneNum = this.basePhoneNum;
        this.invalid = false;
        this.data = [];
        this.seenBtnDwld = false;
        var toAllUsers = false;
        if(this.user !== 'undefined' && this.user !== null){
          console.log("admin select using form ");
          for (var key in this.user) {
              var phoneNum = this.user[key].name;
              let index    = basePhoneNum.findIndex(basePhoneNum => basePhoneNum === phoneNum );
              var userId   = baseUserId[index];
              if(index !== -1) { arrUsers.push(userId); }
              else {
                let invalidNum   = {};
                invalidNum['userId'] = phoneNum;
                this.data.push(invalidNum);  this.invalid = true;
              }
          }
        } else if(this.cbbfile) {
             console.log("admin select using file excel");
             var text  = this.text;
             var str   = text.replace(/\s+/g,"");
             var temp  = str.split(",");
             for (var key in temp) {
                var phoneNum = temp[key];
                let index    = basePhoneNum.findIndex(basePhoneNum => basePhoneNum === phoneNum );
                var userId   = baseUserId[index];
                if(index !== -1) { arrUsers.push(userId); }
                else {
                  let invalidNum   = {};
                  invalidNum['userId'] = phoneNum;
                  this.data.push(invalidNum);
                  this.invalid = true;
                  console.log(invalidNum);
                }
             }
        }

        params['toAllUsers']  = false;
        params['title']       = this.title;
        params['message']     = this.message;
        params['notificationCategory']  = this.defoptCat;
        params['longMessage'] = this.longMessage;

        //check the value of paramenters
        var fields   = ["title","message","notificationCategory","longMessage"];
        for (var key in fields) {
            var keyName = fields[key];
            var status  = !params[keyName] ? false :true;
            validate.push(status);
        } //console.log(params);

        if(validate.includes(false)){
          this.showInvalidMessage();
        } else {
           if (confirm('Notification will be sending, Are you sure ? ')) {
              this.pbar = true;  //console.log(this.cball);
              if(this.stringToBoolean(this.cball)){
                params['toAllUsers'] = true;
                this.sendNotifications(params);
                this.completeSentNotif();
              } else {
                  this.max = arrUsers.length;
                  console.log('max data : ' + this.max);
                  if(this.max !== 0){
                      var j = 1;
                      for (var key2 in arrUsers) {
                          var luserId = arrUsers[key2];
                          arrUser.push(luserId);
                          params['userId'] = arrUser;   //console.log(params);
                          this.sendNotifications(params);
                          arrUser = []; this.value = j; j++;
                      }
                      arrUsers.length = 0;
                      this.completeSentNotif();
                  } else {  this.showInvalidMessage(); }
              }

              if(this.invalid){
                  this.seenBtnDwld = true; console.log(this.data);
              }
           }
        }

      },

      showInvalidMessage(){
          if(this.invalid){
             var msg = "Detected Invalid Phone Number";
          } else {
            var msg = "Data Not Complete, All Data Required";
          }

          this.msg   = msg;
          this.color = 'warning'; this.seen  = true;  this.pbar = false;
          setTimeout( () =>  this.seen=false , 5000);
      },

      completeSentNotif(){

          setTimeout( () =>  this.pbar=false , 5000);
          this.spinner = false;
          this.isDisabledlu = true;
          this.msg   = "Send Notifications Success!";
          this.color = 'success';  this.seen=true;
          setTimeout( () => this.seen=false, 5000);
          this.actionReset();

      },

      confirmSubmit(params){
          if (confirm('You have selected to send notifications to ALL users. Are you sure?')) {
              this.spinner = true;  this.sendNotifications(params);
          }
      },

      actionReset(){
          this.user=[];
          this.cball = false;
          this.onModifycball();
          this.cbbfile = false;
          this.title ='';
          this.message='';
          this.longMessage='';
          this.text='';
          setTimeout( () => this.value=0, 5000);
      },

      onModifycball(){

         var checkAll = this.stringToBoolean(this.cball);

         if(checkAll){
              this.isDisabledlu= false;
              this.isDisabledfile=false;
              this.cbbfile = false;
         } else {
              this.isDisabledlu= true;
              this.isDisabledfile=true;
              //this.cbbfile = true;
              this.cball = false;
        }

         if(this.cbbfile=="true"){ this.isDisabledlu= false; }

      },

      toggleUserSelected(){

      },

      toggleUserUnSelect(){

      },

      sendNotifications: function(params) {
         var diz = this;
         const userId = (params.toAllUsers !== false) ? '' : params.userId[0];
         AccountService.sendNotifications(params, params.toAllUsers).then(function(resp){
            //console.log(resp.data);
            if(resp.message!=="Success"){
              //console.log(userId);
              let invalidNum = {};
              invalidNum['userId'] = userId;
              diz.data.push(invalidNum);
              diz.seenBtnDwld = true;
              //console.log(params);
              //console.log(invalidNum);
            }
        }, error => {
          this.loading = false;
        });
      },

      getNotifTemplateById: function(id) {
          AccountService.getNotifTemplateById(id).then(resp => {   //console.log(resp);
            if(resp.message === "Success"){
                var res      = resp.payload;
                this.title   = res.title;
                this.message = res.preview;
                this.longMessage = res.body;
            }
           }, error => {
            console.log('error');
          });
      },

      getdNotifTemplateList: function() {
         AccountService.getdNotifTemplateList().then(resp => { //console.log(resp);

            var tempSrc=[];
            for (var key in resp) { var row = resp[key];
               tempSrc.push({ value: row.id , text: row.templateName });
            }
            this.optTemplate = tempSrc;

         }, error => { this.loading = false; });
      },

      fetchUsers: function() {
        AuthService.getUserList({page:0, size:10000}).then(resp => {

        var users = resp.payload;
        var items = []; var userId=[]; var basePhoneNum=[];

        for (var key in users) {
            var countryCode = users[key].mobileNumber.countryCode;
            var phoneNum = users[key].mobileNumber.number;
            var ccphoneNum = countryCode+phoneNum;
            items.push({ name : ccphoneNum});
            userId.push(users[key].userIdentify.userId);
            basePhoneNum.push(ccphoneNum);
        }

        //console.log(items);
        this.optusers = items;
        this.baseUserId = userId;
        this.basePhoneNum = basePhoneNum;

        }, error => {
          this.loading = false;
        });
      },

      getNotifCategoryList: function() {
          AccountService.getNotifCategoryList().then(resp => {  //console.log(resp);

            var catSrc=[];
            var catType = resp;
            for (var key in catType) {
                var cat = catType[key];
                catSrc.push({ value: cat.id , text: cat.description });
            }
            //console.log(catSrc);
            this.optCat = catSrc;

           }, error => {
            console.log('error');
          });
      },

      getConfigList: function() {
          AuthService.getConfigList().then(resp => {

            var currSrc=[]; var temp = []; var temp2 = [];
            var arrcurrency = resp.currency;
            for (const item of arrcurrency){ temp.push(item); }
            temp.sort();
            for (var key in temp) {
                var curr = temp[key];
                currSrc.push({ value: curr , text: curr });
            }
            //this.optCurrency = currSrc;

            var bentSrc=[]; var temp2 = [];
            var bentype = resp.promoBenefitTypes;
            for (var key2 in bentype) {
                var ben = bentype[key2];
                bentSrc.push({ value: ben , text: ben });
            }
            //this.optbenType = bentSrc;

            var promoSrc=[]; var temp3 = [];
            var promoType = resp.promoTypes;
            for (var key3 in promoType) {
                var promo = promoType[key3];
                promoSrc.push({ value: promo , text: promo });
            }
            //this.optPromoType = promoSrc;

           }, error => {
            console.log('error');
          });
      },
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue2-timepicker/dist/VueTimepicker.css"></style>
