<template>
       <CCardBody>
         <CRow>
              <CCol md="7">
                    <h4>Send to All Error Report</h4>
              </CCol>
              <CCol md="5">
                   <CInput label="Search" v-model="filter" placeholder="Type to Search" horizontal />
              </CCol>
          </CRow>


          <div class="table-responsive table">
            <b-table striped hover  :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" sort-by="file" :sort-desc="true" >
            <template v-slot:cell(actions)="{ item }">
                <b-button variant="info" @click='downloadCSVData(item.file)' >Download</b-button>
            </template>
            </b-table>
          </div>

          <div>
            <b-row>
              <b-col sm="3">
                 <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
              </b-col>
              <b-col sm="9">
                 <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
                 </b-form-select>
              </b-col>
            </b-row>
          </div>

        </CCardBody>

</template>

<script>
import AuthService from '@/api/AuthService.js';
import AccountService from '@/api/AccountService.js';

 export default {
    name: "report",
    data() {
      return {
        items:[],
        fields: [
          {
            key: 'file',
            label : 'File Report',
            sortable: true
          },
          {
            key: 'actions',
            label: 'Actions'
          }
          ],
        currentPage: 1,
        perPage: 10,
        filter: null,
      };
    },

    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()
        },

        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)
        },

        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'
        },
        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;
        },
        readStatus(value){
           if(value) return "Yes"
           else return "No"
        },


      },

    created () {
       this.getReport();
    },
    methods: {

      getReport: function() {
        AccountService.getNotifReport().then(resp => {   //console.log(resp);
            console.log(resp.payload);
            var lists = [];
            resp.payload.forEach((item, index)=>{
              let tabledata   = {};
              tabledata['file'] = item;
              lists.push(tabledata);
            });
            this.items = lists;
        }, error => {
          this.loading = false;
        });
      },
      downloadCSVData : function(fileName) {
          AccountService.getNotifReportDetail(fileName).then(resp => {   //console.log(resp);
            const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(resp);
            anchor.target = '_blank';
            anchor.download = fileName+'.csv';
            anchor.click();
          }, error => {
            console.log('error');
          });
      }
    }

 };

</script>

<style>
    .table{overflow-y:scroll; }
</style>
